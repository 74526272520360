/**
 * It is an element wrapper for CFDI views.
 * This homologate the view spacings.
 * @param props JSX.Element | React.FC
 * @returns {JSX.Element}
 */
export const CFDIWrapper = (props: { children: JSX.Element }): JSX.Element => {
    return (
        <div
            id='cfdi-wrapper'
            data-component='cfdi-wrapper'
            className='o-component-wrapper u-mt-xsmall@s u-pt-large u-pb-large u-pb-none@m u-mb-none@m u-mt-none@s'
        >
            <div className='o-page-wrap u-pv-xlarge u-pv-none@s'>
                <section className='o-content-section'>
                    {props.children}
                </section>
            </div>
        </div>
    );
};
