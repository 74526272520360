/**
 * Formulary labels
 */
export const HomeLabels = {
    title: 'Registro de facturación',
    subTitle: {
        VWIB: 'Volkswagen Insurance Broker',
        VWL: 'Volkswagen Leasing',
        VWB: 'Volkswagen Bank',
    },
    descriptionTitle: {
        VWIB: 'Estimado cliente.',
        VWL: '',
        VWB: 'Estimado cliente.',
    },
    description: {
        VWIB: {
            descriptionFirstPart:
                'Bienvenido al portal para el registro de datos fiscales de ' +
                'clientes de VWIB, es importante que los datos que ingreses ' +
                'sean los datos que se registran en tu ',
            descriptionSecondPart:
                ' en caso de que no sea así tus documentos fiscales ' +
                'serán generados con datos genéricos y no será posible la modificación ' +
                'una vez generados.',
            document: 'cédula de situación fiscal actualizada',
        },
        VWL: {
            descriptionFirstPart:
                'Bienvenido al portal para el registro de datos fiscales de ' +
                'clientes de VWL, es importante que los datos que ingreses ' +
                'sean los datos que aparecen en tu ',
            descriptionSecondPart:
                ' en caso de que no sea así, tus documentos fiscales ' +
                'serán generados con datos genéricos y no será posible la modificación ' +
                'una vez generados.',
            document: 'Constancia de Situación Fiscal actualizada',
        },
        VWB: {
            descriptionFirstPart:
                'Bienvenido al portal para el registro de datos fiscales de ' +
                'clientes de VWB, es importante que los datos que ingreses ' +
                'sean los datos que se registran en tu ',
            descriptionSecondPart:
                ' en caso de que no sea así tus documentos fiscales ' +
                'serán generados con datos genéricos y no será posible la modificación ' +
                'una vez generados.',
            document: 'cédula de situación fiscal actualizada',
        },
    },
};
