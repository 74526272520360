import React, { useEffect, useState } from 'react';

import { CFDIWrapper } from 'src/Components/Wrapper/wrapper';
import { Footer } from '../../Components/Footer/footer';
import { Header } from '../../Components/Header/headers';
import { HomeLabels } from './home.labels';

declare global {
    function cfdiTest(): void;
    function getCompanyByDomain(): 'VWL' | 'VWB' | 'VWIB';
}

/**
 * Office Access Management Footer
 */
export const Home = () => {
    const [company, setCompany] = useState<'VWL' | 'VWB' | 'VWIB'>('VWIB');

    useEffect(() => {
        window.cfdiTest();
        setCompany(window.getCompanyByDomain());
    });

    return (
        <React.Fragment>
            <Header />
            <CFDIWrapper>
                <div data-component='cfdi-form' className='o-layout'>
                    <div className='o-layout__item u-1/1'>
                        <h1 className='u-mb-none'>{HomeLabels.title}</h1>
                        <h3 className='u-mt-none'>
                            {HomeLabels.subTitle[company]}
                        </h3>
                    </div>

                    <div className='o-layout__item u-1/1'>
                        <label>
                            <b>{HomeLabels.descriptionTitle[company]}</b>
                        </label>
                        <p>
                            {
                                HomeLabels.description[company]
                                    .descriptionFirstPart
                            }
                            <b>{HomeLabels.description[company].document}</b>;
                            {
                                HomeLabels.description[company]
                                    .descriptionSecondPart
                            }
                        </p>
                    </div>
                    <div className='o-layout__item u-1/1'>
                        <div id='cfdi-id'></div>
                    </div>
                </div>
            </CFDIWrapper>
            <Footer />
        </React.Fragment>
    );
};
