export const Footer = () => {
    return (
        <footer
            data-component='cfdi-footer'
            className='c-footer u-pb-large'
            data-theme='brand'
            style={{ color: 'white' }}
        >
            <div className='c-footer__module  c-footer__module--meta-nav'>
                <div className='o-page-wrap u-pv'>
                    <nav className='c-footer-meta-bar'></nav>
                </div>
            </div>
        </footer>
    );
};
