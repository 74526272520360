export const Header = () => {
    return (
        <header data-component='cfdi-header' className='c-header__wrapper'>
            <div className='c-header  ' style={{ backgroundColor: '#f2f2f2' }}>
                <div className='c-header__content-wrapper'>
                    <div className='c-header__logo'>
                        <a
                            className='c-logo  c-logo--has-image'
                            href='#0'
                            title='INSERT YOUR OWN TEXT THAT DESCRIBES THE LINK'
                        >
                            <picture>
                                <source
                                    srcSet='https://cdn.bronson.vwfs.tools/bluelabel/v/12.0.0/img/logo-small-screen.svg'
                                    media='(max-width: 720px)'
                                />
                                <img
                                    src='https://cdn.bronson.vwfs.tools/bluelabel/v/12.0.0/img/logo.svg'
                                    alt='INSERT YOUR OWN ALTERNATIVE TEXT THAT DESCRIBES THE LOGO'
                                    className='c-logo__image'
                                />
                            </picture>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
};
