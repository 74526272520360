import { Home } from './Views/Home/home';

/**
 * Main function to render app
 * @returns App
 */
function App() {
    return <Home />;
}

export default App;
